<template>
    <div class="rxroad">
        <Alarm />
        <router-view/>
        <Nav />
    </div>
</template>

<script>
import Alarm from '@/components/rxroad/alarm.vue'
import Nav from '@/components/nav.vue'

export default {
    components:{
        Nav,Alarm
    },
    data(){
        return{
        }
    }
}
</script>

<style lang="scss">
.amap-copyright,.amap-logo{display: none !important;}
.amap-marker-label{color: #443535;border: 1px solid #d2d2d2 !important;}
.list{content
    .online{color:#23b593 !important;font-weight: bold;}
    .offline{color: #717171 !important;font-weight: bold;}
}
.setting{
    .van-field__label{
        width: 12.2em !important;
    }
}
.global-content{
    .van-cell{justify-content: space-between !important;}
    .van-cell__title, .van-cell__value{
        flex: inherit !important;
    }
    .van-cell__title{
        width: 30% !important;
    }
    .van-cell__value{
        width: 70% !important;
    }
}
</style>
